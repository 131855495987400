import axios from "axios";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { computeSignature, getUserNames } from "../../utils";
import { devApis } from "../../constants/constant";

export const base_url = devApis.PYTHON_BASE_URL;

export const getAllRecommendations = async (accessToken) => {
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;
  const params = new URLSearchParams();
  params.append("org_uuid", uuid);
  const recommendationsUrl = `${
    devApis.GOLANG_BASE_URL
  }/get_all_recommendations?${params.toString()}`;

  try {
    const res = await fetch(recommendationsUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching recommendations:", error.message);
    return [];
  }
};

export const bookmarkRecommendation = async (config, accessToken) => {
  const bookmarkUrl = `${devApis.GOLANG_BASE_URL}/bookmark_recommendation`;

  try {
    const res = await fetch(bookmarkUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(config),
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error bookmarking recommendation:", error.message);
    throw new Error(error);
  }
};

export const getBookmarks = async (accessToken) => {
  const bookmarksUrl = `${devApis.GOLANG_BASE_URL}/get_bookmarks`;

  try {
    const res = await fetch(bookmarksUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching bookmarks:", error.message);
    return [];
  }
};

export const bookmarkDelete = async (config, accessToken) => {
  const deleteUrl = `${devApis.GOLANG_BASE_URL}/delete_bookmark`;

  try {
    const res = await fetch(deleteUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(config),
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const searchByQuery = async (searchQuery, accessToken) => {
  try {
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    const res = await fetch(
      `${
        devApis.GOLANG_BASE_URL
      }/get_all_recommendations?search_query=${searchQuery}&${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      throw new Error("401");
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const searchBookmarkByQuery = async (searchQuery, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/get_bookmarks?search_query=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getAllConversations = async (queryParams, accessToken) => {
  try {
    const res = await fetch(
      `${base_url}/get_all_conversations?${queryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getChatHistory = async (nextQueryParams, accessToken) => {
  try {
    const res = await fetch(
      `${base_url}/get_chat_history?${nextQueryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const feedbackUpvote = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.GOLANG_BASE_URL}/feedback_upvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getUpvotes = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/feedback_upvote?recommendation_id=${recommendationId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting upvotes:", error.message);
    throw new Error(error);
  }
};

export const getDownvotes = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/feedback_downvote?recommendation_id=${recommendationId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting downvotes:", error.message);
    throw new Error(error);
  }
};

export const feedbackDownvote = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.GOLANG_BASE_URL}/feedback_downvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const upload = async (formData, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/upload_file`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const converCSVToTable = async (formData, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getFIleRecommendation = async (fileRes, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_recommendations?s3_key=${fileRes.s3_key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const startConversation = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/start_conversation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const addMessageToSession = async (
  newSessionId,
  promptVlaue,
  accessToken,
  filename
) => {
  let updatedFileName;
  if (typeof filename === "string") {
    updatedFileName = filename.split("_").slice(1).join("_");
  } else if (Array.isArray(filename)) {
    updatedFileName = filename.map((name) =>
      name.split("_").slice(1).join("_")
    );
  }

  let filteredFileNames;
  if (Array.isArray(updatedFileName)) {
    filteredFileNames = [...new Set(updatedFileName)];
  } else {
    filteredFileNames = updatedFileName;
  }

  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/add_message_to_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          session_id: newSessionId,
          prompt: promptVlaue,
          filename: filteredFileNames,
        }),
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      console.error(`Failed to add message: ${res.status} ${res.statusText}`);
      throw new Error(`Failed to add message: ${res.status} ${res.statusText}`);
    }
  } catch (error) {
    console.error("Error adding message:", error.message);
    throw new Error(error);
  }
};

export const regenerateRecommendation = async (
  recommendationId,
  accessToken,
  filename
) => {
  const updatedFileName = filename.split("_").slice(1).join("_");
  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/api/recommendation/regenerate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          recommendation_id: recommendationId,
          filename: updatedFileName,
        }),
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getRecByS3Keys = async (data, sendDataToParent, fileRes) => {
  let respData = {};
  const token = await checkAndRefreshToken();
  const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";
  try {
    if (data.length === 0) throw new Error("No s3_keys");
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    // const res = await fetch(
    //   `${
    //     devApis.GOLANG_BASE_URL
    //   }/api/get_recommendations_by_s3_key?${params.toString()}`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //     body: JSON.stringify({ s3_keys: data }),
    //   }
    // );
    // const recommendationData = await res.json();

    const getRecommendationsData = async (data) => {
      try {
        const recommendationData = await Promise.all(
          data.map(async (s3_key) => {
            const getRecommendationsSignature = computeSignature(
              secretKey,
              "GET",
              "/get_recommendations",
              { s3_key: s3_key, org_uuid: uuid },
              {},
              "",
              ""
            );

            const respS3 = await fetch(
              `${
                devApis.PYTHON_BASE_URL
              }/get_recommendations?s3_key=${s3_key}&${params.toString()}`,
              {
                method: "GET",
                headers: {
                  "x-signature": getRecommendationsSignature,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            const resData = await respS3.json();
            return resData;
          })
        );

        return recommendationData;
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        throw error;
      }
    };

    await getRecommendationsData(data)
      .then((results) => {
        respData.recommendations = results;
      })
      .catch((error) => {
        console.error("Failed to fetch recommendations:", error);
      });

    const bookmarkedResp = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/get_bookmarked_recommendations_by_s3_key`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ s3_keys: data }),
      }
    );
    const bookmarkedData = await bookmarkedResp.json();
    if (data.length > 1 && respData) {
      const recId = respData?.recommendations.map((item) => item.id);

      const combineResp = await fetch(
        `${
          devApis.PYTHON_BASE_URL
        }/generate_combined_recommendations?${params.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ s3_keys: data, recommendation_ids: recId }),
        }
      );
      const combineData = await combineResp.json();

      const result = {
        recommendations: respData,
        bookmarked_recommendations: bookmarkedData,
        combined_recommendations: combineData,
      };
      sendDataToParent(result);
      return;
    }

    const result = {
      recommendations: respData,
      bookmarked_recommendations: bookmarkedData,
      combined_recommendations: [],
    };
    sendDataToParent(result);
  } catch (error) {
    sendDataToParent(null);
    throw new Error(error);
  }
};

export const getFilesList = async () => {
  const token = await checkAndRefreshToken();
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;
  const params = new URLSearchParams();
  params.append("org_uuid", uuid);
  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/uploaded_files?${params.toString()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();

      const sortedFiles = data?.files?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return sortedFiles;
    } else {
      // throw new Error(error);
      throw new Error();
    }
  } catch (error) {
    throw new Error(error);
  }
};

export async function CreateChildRecommendation(model) {
  try {
    model = JSON.stringify(model);
    const token = await checkAndRefreshToken();
    await axios.post(
      `${devApis.GOLANG_BASE_URL}/create_custom_child_recommendation`,
      model,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    fetchData(model);
    return model;
  } catch (error) {
    return error;
  }
}

export const fetchData = async () => {
  const token = await checkAndRefreshToken();
  const url = `${devApis.GOLANG_BASE_URL}/api/get_custom_child_recommendation`;

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getFileName = async () => {
  const token = await checkAndRefreshToken();
  const url = `${devApis.GOLANG_BASE_URL}/api/file_types`;

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err.message);
  }
};

export const editChild = async (file, accessToken) => {
  try {
    const parent_id = file.parent_id;
    const response = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/edit_custom_child_recommendation/${parent_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(file),
      }
    );
    const updatedFile = await response.json();
    return updatedFile;
  } catch (err) {
    return err;
  }
};

export const deleteChild = async (file, accessToken) => {
  try {
    const response = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/delete_custom_child_recommendation/${file.ID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    await response.json();
  } catch (err) {
    return err;
  }
};

export async function CreateCustomRecommendation(model) {
  try {
    model = JSON.stringify(model);
    const token = await checkAndRefreshToken();
    const response = await axios.post(
      `${devApis.GOLANG_BASE_URL}/create_custom_parent_recommendation`,
      model,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    fetchData();
    return response.data.parent_recommendation;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const recommendationVisited = async (id) => {
  const url = `${devApis.GOLANG_BASE_URL}/api/mark_recommendation_as_viewed`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ recommendation_id: id }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const bookmarkVisited = async (id) => {
  const url = `${devApis.GOLANG_BASE_URL}/api/mark_bookmark_as_viewed`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ bookmark_id: id }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const selectRecommendations = async (recId) => {
  const url = `${devApis.PYTHON_BASE_URL}/api/select`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        recommendation_id: recId,
        selected: true,
      }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const selectAllRecommendations = async () => {
  const url = `${devApis.PYTHON_BASE_URL}/api/selectAll`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selected: true,
      }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchDataCustomChildRecommendations = async (fileType) => {
  const queryParams = new URLSearchParams();
  queryParams.append("file_type", fileType);
  const decoded = fileType.replace(/[{}]/g, "");
  const url = `${devApis.GOLANG_BASE_URL}/api/get_custom_child_recommendation?file_type=${decoded}`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return {
        childData: data?.custom_child_recommendations?.slice(0, 2),
        hasChild: true,
      };
    } else {
      return { childData: [], hasChild: false };
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const newConversation = async (promptText) => {
  try {
    const promptResponse = await fetch(
      `${devApis.PYTHON_BASE_URL}/start_conversation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          recommendation_id: 3,
        }),
      }
    );
    const data = await promptResponse.json();
    const newSessionId = data.session_id;

    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/add_message_to_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          session_id: newSessionId,
          prompt: promptText,
        }),
      }
    );

    if (response.status === 200 || response.status === 201) {
      const data2 = await response.json();
      return data2;
    } else {
      console.error(
        `Failed to add message: ${response.status} ${response.statusText}`
      );
      throw new Error(
        `Failed to add message: ${response.status} ${response.statusText}`
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const regenerateResponse = async (
  messageId,
  input,
  sessionId,
  filename
) => {
  let updatedFileName;
  const token = await checkAndRefreshToken();
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;

  if (typeof filename === "string") {
    updatedFileName = filename.split("_").slice(1).join("_");
  } else if (Array.isArray(filename)) {
    updatedFileName = filename.map((name) =>
      name.split("_").slice(1).join("_")
    );
  }
  try {
    const promptResponse = await fetch(
      `${devApis.PYTHON_BASE_URL}/refresh_message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: messageId,
          session_id: sessionId,
          filename: updatedFileName,
          user_question: input,
          org_uuid: uuid,
        }),
      }
    );
    // const data = await promptResponse.json();
    // return data;
    if (promptResponse.status === 200 || promptResponse.status === 201) {
      const data = await promptResponse.json();
      return data;
    } else {
      console.error(
        `Failed to add message: ${promptResponse.status} ${promptResponse.statusText}`
      );
      throw new Error(
        `Failed to add message: ${promptResponse.status} ${promptResponse.statusText}`
      );
    }
  } catch (error) {
    console.error("Error adding message:", error.message);
    throw new Error(error);
  }
};

export const bookmarkQuestions = async (messageId) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/save_message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message_id: messageId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSavedQuestions = async () => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_saved_messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteSavedQuestions = async (messageId) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/delete_message`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message_id: messageId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteConversations = async (id) => {
  const token = await checkAndRefreshToken();
  try {
    const params = new URLSearchParams();
    params.append("recommendation_id", id);
    const response = await fetch(
      `${
        devApis.PYTHON_BASE_URL
      }/delete_conversations_by_recommendation?${params.toString()}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
