export const responseGoogle = async (response) => {
  // console.log('my response', response);
  try {
    const resp = await fetch(
      "`https://api.trypadlock.com/padlockAdmin/google_data_sign",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          token: response,
        }),
      }
    );

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.log("error", error.message);
  }
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const refreshUrl = `https://api.trypadlock.com/padlockAdmin/refresh`;

  try {
    const res = await fetch(refreshUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!res.ok) {
      throw new Error("Failed to refresh token");
    }

    const updatedData = await res.json();

    // Store the new tokens

    return {
      status: res.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Token refresh failed:", error);
    // Handle token refresh failure, e.g., redirect to login
    window.location.href = "/login";
  }
};

export const loginUser = async (payload) => {
  const loginUrl = `https://api.trypadlock.com/padlockAdmin/signup`;
  const data = {
    appName: "strived",
    appId: 50,
    email: payload.email,
    org_name: payload.org_name,
    org_uuid: payload.org_uuid,
  };
  try {
    const res = await fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error while sign up:", error.message);
  }
};

export const verifyLogin = async (payload) => {
  const loginUrl = `https://api.trypadlock.com/padlockAdmin/login_otp_verification`;
  const data = {
    appName: "strived",
    appId: 50,
    email: payload.email,
    otp: payload.otp,
    org_name: payload.org_name,
    org_uuid: payload.org_uuid,
  };

  try {
    const res = await fetch(loginUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error while verifying otp:", error.message);
  }
};

export const getOrganizationList = async (email) => {
  const url = `https://api.trypadlock.com/padlockAdmin/organization_require`;
  const data = {
    email: email,
    appName: "strived",
    appId: 50,
  };
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};
