import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { darkTheme } from "../../theme";
import Button from "../../components/button";
import signupLogo from "../../assets/strived-logo-icon.svg";
import {
  // getOrganizationList,
  loginUser,
  verifyLogin,
} from "../../apis/login/login";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Otp = (props) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const { email, selectedOrg, selectedOrgUuid } = props;

  const handleLogin = async () => {
    let updatedData = await verifyLogin({
      email: email,
      org_name: selectedOrg,
      org_uuid: selectedOrgUuid,
      otp: otp,
    });
    if (updatedData.status === 200) {
      const decodedToken = jwt_decode(updatedData?.data?.token);
      if (decodedToken !== undefined) {
        const user = decodedToken.data;
        let userDetails = {
          org_uuid: user.org_uuid,
          user_uuid: user.user.user_uuid,
          email: user.user.email,
        };

        localStorage.setItem("token", updatedData?.data?.token);
        localStorage.setItem("refresh_token", updatedData?.data?.refresh_token);
        localStorage.setItem("user_email", user.user.email);
        localStorage.setItem("org", selectedOrg);
        localStorage.setItem("user", JSON.stringify(userDetails));
        localStorage.setItem(
          "userName",
          user?.userdetails?.user_data?.first_name
        );
        navigate("/");
      }
    } else {
      toast.error(updatedData?.data?.message);
    }
  };

  const handleEmailContinue = async () => {
    try {
      const loginResp = await loginUser({
        email: email,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      if (loginResp?.status === 200) {
        toast.success("Resent the OTP");
      } else {
        toast.error("Failed to resend the OTP");
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-screen flex flex-col xs:p-2">
        <div className="sio-col-420 mb-auto">
          <img
            src={signupLogo}
            alt="signup-page-logo"
            className="mt-12 mb-2 xs:h-10"
          />
          <div className="sio-card bg-white-gradient">
            <div className="text-center">
              <h1>Verify Your Account</h1>
              <p className="text-secondary-light text-xs sm:text-sm">
                We have sent a 6-digit code to your email. Please enter it
                below. Can't find it? Check your spam folder.
              </p>
            </div>

            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    width: window.innerWidth <= 320 ? "35px" : "50px",
                    backgroundColor: "inherit",
                  }}
                  className="w-14 h-14 text-2xl text-center border border-gray-500 rounded-md bg-black 
                  focus:outline-none focus:border-pink-500 focus:border-2 text-white"
                />
              )}
              containerStyle="flex space-x-3 mb-6"
            />
            <Button
              variant="secondary"
              onClick={() => {
                handleLogin();
              }}>
              Verify
            </Button>
            <p className="text-secondary-light text-xs sm:text-sm text-center">
              Didn't receive the code?{" "}
              <span
                className="sio-link-secondary hover:cursor-pointer"
                onClick={() => handleEmailContinue()}>
                {" "}
                Resend
              </span>
            </p>
          </div>
        </div>
        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                {" "}
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/">
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©2024 All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Otp;
